
  import {mapGetters} from "vuex";

  export default {
    components: {
      Topbar: () => import("~/components/global/Topbar"),
      Bottombar: () => import("~/components/global/Bottombar"),
      PageLoader: () => import("~/components/global/modal/PageLoader"),
      PreContentLoader: () => import("~/components/global/modal/PreContentLoader"),
      ApiLoader: () => import("~/components/global/modal/ApiLoader"),
      Catcher: () => import("~/components/global/modal/Catcher"),
      ContentSpaceFiller: () => import("~/components/global/ContentSpaceFiller"),
    },
    data: () => ({
      padding: 0,
    }),
    watch: {
      $route (to, from)
      {
        this.$store.commit("global/catcher/hideHasError", {status: false});
        this.routeChecklist();
        this.autoPadding();
      },
    },
    computed: {
      ...mapGetters({
        getShowStatus: "global/modal/getShowStatus",
        getHasError: "global/catcher/getHasError",
      }),
    },
    methods: {
      routeChecklist ()
      {
        this.onResize();
      },
      onResize ()
      {
        if (document.documentElement && document.documentElement.clientWidth)
        {
          if (document.documentElement.clientWidth <= 1024)
          {
            this.$store.commit("global/settings/enteredMobile", {status: true});
          } else
          {
            this.$store.commit("global/settings/enteredMobile", {
              status: false,
            });
          }
        }
        this.autoPadding();
      },
      autoPadding ()
      {
        let target_height = document.querySelector(".__n"),
          result = 0;

        if (target_height)
        {
          result = `${ target_height.offsetHeight }px`;
        }
        this.padding = result;
      },
      googleAnalytics ()
      {
        (function ()
        {
          let google_analytics = document.createElement("script"),
            google_analytics_script = document.createElement("script"),
            google_ads = document.createElement("script"),
            google_ads_script = document.createElement("script"),
            script = document.getElementsByTagName("script")[0],
            google_gtm = document.createElement("noscript"),
            google_gtm_script = document.createElement("script")

          google_gtm.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KF2LKJF"height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          google_gtm_script.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KF2LKJF');`;
          script.parentNode.insertBefore(google_gtm, script);
          script.parentNode.insertBefore(google_gtm_script, script);

          google_analytics.async = true;
          google_analytics.src = "https://www.googletagmanager.com/gtag/js?id=UA-39035388-11";
          google_analytics_script.innerText = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-39035388-11');";
          script.parentNode.insertBefore(google_analytics, script);
          script.parentNode.insertBefore(google_analytics_script, script);

          google_ads.async = true;
          google_ads.src = "https://www.googletagmanager.com/gtag/js?id=AW-10879139176";
          google_ads_script.innerText = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10879139176');";
          script.parentNode.insertBefore(google_ads, script);
          script.parentNode.insertBefore(google_ads_script, script);
        })();
      },
      jsonLd ()
      {
        (function ()
        {
          const json_ld = document.createElement('script');
          json_ld.setAttribute('type', 'application/ld+json');
          const ld_data = `{"@context":"https://schema.org","@type":"InsuranceAgency","name":"Malayan Insurance","image":"https://malayan.com/_ipx/cbimage.png","@id":"","url":"https://malayan.com/","telephone":"632 8628 8628","address":{"@type":"PostalAddress","streetAddress":"4F Yuchengco Tower I 484 Quintin Paredes St. Binondo","addressLocality":"Manila","postalCode":"1006","addressCountry":"PH"},"geo":{"@type":"GeoCoordinates","latitude":14.5988884,"longitude":120.975891},"openingHoursSpecification":{"@type":"OpeningHoursSpecification","dayOfWeek":["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"],"opens":"00:00","closes":"23:59"},"sameAs":["https://www.facebook.com/MalayanInsurancePH/","https://www.instagram.com/malayaninsuranceph/","https://www.linkedin.com/company/malayan-insurance-co-inc-/posts/?feedView=all"]}`
          json_ld.textContent = ld_data;
          document.head.appendChild(json_ld);
        })();
      },      
      chatbot ()
      {
        window.__be = window.__be || {};
        window.__be.id = "64fab714714b8900074d64a2";
        (function ()
        {
          var be = document.createElement("script");
          be.type = "text/javascript";
          be.async = true;
          be.src =
            ("https:" == document.location.protocol ? "https://" : "http://") +
            "cdn.chatbot.com/widget/plugin.js";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(be, s);
        })();        
      },
      updateSitemap ()
      {
        const me = this;
      },
    },
    mounted ()
    {
      this.updateSitemap();
      this.googleAnalytics();
      this.jsonLd();
      this.chatbot();
      this.onResize();
      this.toggleModalStatus({type: "announcements", status: true});
      window.addEventListener("load", this.onResize);
      window.addEventListener("resize", this.onResize);      
      document.addEventListener("contextmenu", (event) => event.preventDefault());
      setTimeout(() => {
        this.autoPadding();
      }, 500);
    },
    destroyed ()
    {
      window.removeEventListener("load", this.onResize);
      window.removeEventListener("resize", this.onResize);
    },
  };
