export default () => ({
  links: [
    {
      id: 1,
      title: "Contact Us",
      url: "/contact-us",
      show: false,
      type: 1,
      children: [
        {
          name: "4F Yuchengco Tower I 484 Quintin Paredes St. Binondo, Manila",
          url: null,
          image: "/assets/bottombar/location-icon.webp",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" /></svg>`,
          alt: "Office Location",
        },
        {
          name: "(63-2)8628-8600",
          url: null,
          image: "/assets/bottombar/phone-icon.webp",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" /></svg>`,
          alt: "Office Phone",
        },
        {
          name: "cxc@malayan.com",
          url: 'mailto:cxc@malayan.com',
          image: "/assets/bottombar/mail.svg",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" /><path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" /></svg>`,
          alt: "CXC Malayan",
        },
      ],
    },
    {
      id: 1,
      title: "About Us",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Company Background",
          alt: "Company Background",
          url: "/about-us#intro",
        },
        {
          name: "YGC",
          alt: "YGC",
          url: "/about-us#ygc",
        },
        {
          name: "Our Partners",
          alt: "Our Partners",
          url: "/about-us#partners",
        },
        {
          name: "History",
          alt: "History",
          url: "/about-us#history",
        },
      ],
    },
    {
      id: 2,
      title: "Products",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Assets and Property",
          alt: "Assets and Property",
          url: "/products/assets-and-property",
        },
        {
          name: "Personal and Family",
          alt: "Personal and Family",
          url: "/products/personal-and-family",
        },
        {
          name: "Lifestyle",
          alt: "Lifestyle",
          url: "/products/lifestyle",
        },
      ],
    },
    {
      id: 3,
      title: "Claims and Support",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Claim Process",
          url: "/claims-and-support/claim-process",
        },
        {
          name: "Claim Form",
          url: "/claims-and-support/claim-form",
        },
        {
          name: "FAQs",
          url: "/claims-and-support/faqs",
        },
        {
          name: "CX Handbook",
          url: "/contact-us",
        },
      ],
    },
    {
      id: 4,
      title: "News",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Car",
          url: "/news/category/cars",
        },
        {
          name: "Business",
          url: "/news/category/business",
        },
        {
          name: "Home",
          url: "/news/category/home",
        },
        {
          name: "Travel",
          url: "/news/category/travel",
        },
        {
          name: "Finance",
          url: "/news/category/finance",
        },
      ],
    },
  ],
})
